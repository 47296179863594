export const EXTERN_TEAMS_IDS = {
  BuHa: 9,
  FM: 7,
  MVM: 6,
  NKA: 8,
}

export const INTERN_TEAMS_IDS = {
  MVM: 1,
  FM: 1,
  NKA: 1,
  FoMa: 1,
  BackOffice: 1,
  BuHa: 1,
}